import React, { createRef, useMemo, useState } from "react";
import { graphql, Link } from "gatsby";
import algoliasearch from "algoliasearch/lite";
import { GatsbyImage } from "gatsby-plugin-image";
import { InstantSearch } from "react-instantsearch-dom";
import Layout from "@components/layout";
import Seo from "@components/seo";
import Footer from "@components/FooterSection";
import useDebounce from "@utils/debounce";
import Breadcrumb from "@components/Breadcrumb";
import { DeepNonNullable } from "ts-essentials";
import { AllPostsDataQuery } from "../../graphqlTypes";
import styled from "styled-components";
import { TAILWIND_DEFAULTS } from "@utils/GlobalConstants";
import Pagination, { BlogContext } from "@ui/Pagination";
import useOnClickOutside from "@utils/hooks/useOnClickOutside";
import SearchBoxAlgolia from "@components/SearchBoxAlgolia";
import SearchResult from "@components/SearchResultsAlgolia";
import { DateOptions } from "@utils/dateFormat";

export const BlogPosts = graphql`
  query AllPostsData($skip: Int!, $limit: Int!) {
    strapiBlog {
      pageTitle
      title
      description
      searchTitle
      searchPlaceholder
    }
    allStrapiBlogPosts(
      limit: $limit
      skip: $skip
      sort: { fields: [published_at], order: DESC }
    ) {
      edges {
        node {
          title
          Preview
          id
          slug
          published_at
          Content
          FeaturedImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 95
                  placeholder: BLURRED
                  # layout: FULL_WIDTH
                  width: 200
                )
              }
            }
          }
          blog_author {
            AuthorName
            AuthorJobTitle
            AuthorFacebook
            AuthorTwitter
            AuthorLinkedIn
            AuthorDescription
            AuthorImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 95)
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        algoliaApiKey
        algoliaAppId
      }
    }
  }
`;

const ArrowRight = styled.div`
  /* margin-top: 10%; */
  background-color: rgb(229, 231, 235);
  transform: rotate(-45deg);
  border-radius: 10%;
  left: calc(100% + -25px);
  display: inline-table;
  width: 30px;
  height: 30px;
  position: relative;
  top: -80%;
  z-index: 0;
  /* As the md breakpoint in tailwind */
  @media (max-width: ${TAILWIND_DEFAULTS.screens.md}) {
    display: none;
  }
`;

const SVGLines = () => (
  <div
    className="absolute h-full"
    style={{
      top: "-40px",
      left: "20px",
      zIndex: -1,
    }}
  >
    <svg width="48" height="6">
      <circle
        cx="45"
        cy="3"
        r="2"
        fill="white"
        stroke="#C7DB4E"
        stroke-width="2"
      />
      <circle
        cx="31"
        cy="3"
        r="2"
        fill="white"
        stroke="#82CA69"
        stroke-width="2"
        vector-effect="non-scaling-stroke"
      />
      <circle
        cx="17"
        cy="3"
        r="2"
        fill="white"
        stroke="#38E2A5"
        stroke-width="2"
        vector-effect="non-scaling-stroke"
      />
      <circle
        cx="3"
        cy="3"
        r="2"
        fill="white"
        stroke="#38B9E2"
        stroke-width="2"
        vector-effect="non-scaling-stroke"
      />
    </svg>
    <svg
      width="48"
      height="100%"
      viewBox="0 0 48 36"
      fill="none"
      preserveAspectRatio="none"
      style={{
        marginTop: "-2px",
      }}
    >
      <rect
        x="47"
        y="36"
        height="100%"
        width="4.00001"
        transform="rotate(-180 47 36)"
        fill="#C7DB4E"
      />
      <rect
        x="33"
        y="36"
        width="4.00001"
        height="100%"
        transform="rotate(-180 33 36)"
        fill="#82CA69"
      />
      <rect
        x="19"
        y="36"
        width="4.00001"
        height="100%"
        transform="rotate(-180 19 36)"
        fill="#38E2A5"
      />
      <rect
        x="5"
        y="36"
        width="4.00001"
        height="100%"
        transform="rotate(-180 5 36)"
        fill="#38B9E2"
      />
    </svg>
  </div>
);

const BlogPage = ({
  data,
  pageContext,
}: {
  data: DeepNonNullable<AllPostsDataQuery>;
  pageContext: BlogContext;
}) => {
  const { algoliaApiKey, algoliaAppId } = data.site.siteMetadata;
  const { edges } = data.allStrapiBlogPosts;
  const { pageTitle, title, description, searchTitle, searchPlaceholder } =
    data.strapiBlog;

  /** Search Related Section */
  const rootRef = createRef<HTMLElement>();
  const [query, setQuery] = useState<string>();
  /** To Avoid glitching effect on frequent updates of activeSection */
  const debouncedQuery = useDebounce<string | undefined>(query, 120);
  const [hasFocus, setFocus] = useState(false);
  const searchClient = useMemo(
    () => algoliasearch(algoliaAppId, algoliaApiKey),
    []
  );
  useOnClickOutside(rootRef, () => setFocus(false));
  /** End of Search Related Section */

  const indices = [{ name: `dev_blogposts`, title: `dev_blogposts` }];

  return (
    <Layout>
      <Seo title={pageTitle} />
      <div className="div-block flex flex-col max-w-7xl mx-5 md:mx-auto px-4 sm:px-0 mt-4 mb-4">
        <Breadcrumb title="Blog" />
        <div className="grid grid-cols-5 gap-4 pb-24">
          <div className="col-start-1 col-end-6 md:col-end-5">
            <h1 className="text-4xl text-white font-semibold mt-16 mb-6">
              {title}
            </h1>
            <p className="text-sm text-gray-300 font-light">{description}</p>
          </div>
        </div>
      </div>
      <div className="full-width-strip bg-accuRankerCream">
        <div className="div-block max-w-fit md:max-w-7xl bg-accuRankerCream w-full pb-12 mx-5 md:m-auto px-0 sm:px-0">
          <div className="max-w-7xl flex flex-col gap-11 m-auto sm:px-0">
            <div>
              <div className="relative z-10">
                <SVGLines />
                <div className="bg-accuRankerPurple-500 rounded-lg w-full z-10 px-10 py-8 -mt-20 mb-10 shadow-lg">
                  <div
                    ref={rootRef as React.RefObject<HTMLDivElement>}
                    className="relative"
                  >
                    <InstantSearch
                      searchClient={searchClient}
                      indexName={indices[0].name}
                      onSearchStateChange={({ query }) => setQuery(query)}
                    >
                      <span className="text-accuRankerPurpleOpacity-light">
                        {searchTitle}
                      </span>
                      <SearchBoxAlgolia
                        onFocus={() => setFocus(true)}
                        placeholder={searchPlaceholder}
                      />
                      {hasFocus &&
                        debouncedQuery &&
                        debouncedQuery.length > 0 && (
                          <SearchResult
                            className="bg-white rounded-lg p-4 absolute w-full overflow-y-scroll z-50 shadow-md"
                            indices={indices}
                          />
                        )}
                    </InstantSearch>
                  </div>
                </div>
                <div className="w-full flex flex-col gap-8">
                  {edges.map(edge => {
                    const {
                      node: {
                        FeaturedImage: {
                          localFile: {
                            childImageSharp: { gatsbyImageData: postImage },
                          },
                        },
                        slug,
                        Preview,
                        title,
                        published_at,
                        blog_author: {
                          AuthorImage: {
                            localFile: {
                              childImageSharp: { gatsbyImageData: authorImage },
                            },
                          },
                          AuthorName,
                        },
                      },
                    } = edge;
                    return (
                      <Link to={`/blog/${slug}/`}>
                        <div className="flex flex-row rounded-lg cursor-pointer bg-gray-100 border-2 shadow-lg">
                          {postImage && (
                            <div
                              className="bg-gray-100 relative rounded-lg"
                              style={{ width: "200px", zIndex: 0 }}
                            >
                              <GatsbyImage
                                alt={AuthorName}
                                objectFit="cover"
                                imgClassName="rounded-lg"
                                className="rounded-lg h-full"
                                image={postImage}
                                style={{ width: "200px" }}
                              />
                              <ArrowRight />
                            </div>
                          )}
                          <div
                            className="flex flex-col p-6 rounded-lg gap-2 bg-gray-100"
                            style={{
                              marginLeft: "-14px",
                              zIndex: 10,
                            }}
                          >
                            <h2 className="text-2xl">{title}</h2>
                            <span className="text-gray-700">{Preview}...</span>
                            <span className="text-gray-500 text-sm">
                              {new Date(published_at).toLocaleString(
                                "en-US",
                                DateOptions
                              )}
                            </span>
                            {/* Authors can be multiple */}
                            {/*<div className="w-12 h-12 rounded-xl overflow-hidden">*/}
                            {/*  <GatsbyImage*/}
                            {/*    alt={AuthorName}*/}
                            {/*    image={authorImage}*/}
                            {/*  />*/}
                            {/*</div>*/}
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
              <div className="my-6">
                <Pagination {...pageContext} />
              </div>
              {/*<NewsletterBanner*/}
              {/*  onClick={() => {*/}
              {/*    alert("implement me");*/}
              {/*  }}*/}
              {/*/>*/}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default BlogPage;
